//$primary-color: green;
$sticky-bg-color: var(--gray-darkest);
$cell-font-size: 1em;
$cell-text-color: white;
@import "@silevis/reactgrid/styles.scss";

.rg-cell {
    background-color: var(--gray-dark);
    //background-color: green;
}

.rg-header-cell {
    background-color: var(--gray-darkest) !important;
}

.rg-celleditor {
    border-width: 0;
    padding: 0;
    position: absolute !important;
    visibility: hidden;
}

/*
.rg-textarea-celleditor,
.rg-startchat-celleditor {
    position: absolute !important;
    visibility: hidden;
}
*/
